import { Configuration, PopupRequest } from "@azure/msal-browser";

// Config Azure AD app setting to be passed to Msal on creation
const TenantId = "c012e57e-c44f-48e7-bcad-11ad1a2d285f";
const ClientId = "ced69c21-4058-49d8-b1b8-56fc8505c591";

export const msalConfig: Configuration = {
    auth: {
        clientId: ClientId,
        authority: "https://login.microsoftonline.com/" + TenantId,
        redirectUri: "/",
        postLogoutRedirectUri: "/"        
    }
};

export const userPermissionScopes: string[] = [
    "api://" + ClientId + "/Reports.Embed"
]

export const PowerBiLoginRequest: PopupRequest = {
  scopes: userPermissionScopes
};